import { FORBIDDEN, CONFLICT } from 'http-status-codes';
import { UNAUTHORIZED } from 'http-status-codes/index';
import { all, call, put, takeLatest } from 'redux-saga/effects';
import { history } from '../../core/history';
import { AuthenticationService } from '../../services';
import {
  addParamToUrl,
  errorCodes,
  getQueryParamsFromUrl,
  origins,
  Routes,
} from '../../utils';
import { CommonActions } from '../common';
import { LoaderActions } from '../loader';
import { SnackActions } from '../snackBar';
import { default as AuthActions, types } from './actions';

function* login({ login, password, origin, redirectUrl }) {
  yield put(LoaderActions.loading());
  const [error, response] = yield call(
    AuthenticationService.login,
    login,
    password,
    origin,
    redirectUrl
  );

  if (error) {
    const { response } = error;
    if (
      response &&
      response.status === FORBIDDEN &&
      response.data &&
      response.data.code === errorCodes.FORBIDDEN.INVALID_REDIRECTION
    ) {
      yield put(SnackActions.displayError('invalid_redirect'));
    } else if (response.data.code === 'BadCredentials') {
      yield put(SnackActions.displayError('bad_credentials'));
    } else {
      yield put(SnackActions.displayError('login_failed'));
    }
  } else {
    yield put(
      AuthActions.loginSucceed(
        response.data.id,
        response.data.accessToken,
        response.data.refreshToken
      )
    );

    // If the login has no origin or is from the authentication platform, no need to generate tokens
    if (origin && origin !== origins.AUTHENT && redirectUrl) {
      yield call(generateTokens, {
        domain: origin,
        redirectUrl,
        comeFromLogin: true,
      });
    } else {
      yield call(history.replace, Routes.REDIRECT);
    }
  }

  yield put(LoaderActions.loaded());
}

function* generateTokens({ domain, redirectUrl, comeFromLogin }) {
  yield put(LoaderActions.loading());
  const [error, response] = yield call(
    AuthenticationService.generateTokens,
    domain,
    redirectUrl
  );

  //If error is UNAUTHORIZED, it has been handled in the authorizeService middleware
  if (error && error.response.status !== UNAUTHORIZED) {
    if (comeFromLogin) {
      yield call(history.replace, Routes.REDIRECT);
    }

    const { response } = error;
    if (
      response &&
      response.status === FORBIDDEN &&
      response.data &&
      response.data.code === errorCodes.FORBIDDEN.INVALID_REDIRECTION
    ) {
      yield put(SnackActions.displayError('invalid_redirect'));
    } else if (
      response &&
      response.status === FORBIDDEN &&
      response.data &&
      response.data.code === errorCodes.FORBIDDEN.ACCESS_DENIED
    ) {
      yield put(SnackActions.displayError('access_forbidden'));
    } else {
      yield put(SnackActions.displayError('auth_failed'));
    }
  } else {
    const url = addParamToUrl(
      redirectUrl,
      {
        key: 'accessToken',
        value: response.data.accessToken,
      },
      { key: 'refreshToken', value: response.data.refreshToken }
    );

    if (comeFromLogin) {
      window.open(url, '_self');
    } else {
      /* Safari does not allow using window.open in async actions */
      setTimeout(() => window.open(url, "_blank"));
    }
  }
  yield put(LoaderActions.loaded());
}

function* logout() {
  yield put(CommonActions.resetReducers());
  // We don't make a yield call(history.replace, Routes.DEFAULT_ROUTE) to cancel api call automatically
  let { origin, redirectUrl } = getQueryParamsFromUrl(window.location.href);
  window.location = addParamToUrl(
    Routes.DEFAULT_ROUTE,
    {
      key: 'origin',
      value: origin,
    },
    {
      key: 'redirectUrl',
      value: redirectUrl,
    }
  );
}

function* resetPassword({email, callFrom} ) {
  yield put(LoaderActions.loading());
  const [error, response] = yield call(AuthenticationService.resetPassword, email, callFrom);
  yield put(LoaderActions.loaded())
  if (response) {
    yield put(SnackActions.displaySuccess('reset_succeeded'))
  } else {
    yield put(SnackActions.displayError('reset_failed'));
  }
}


function* changePassword({ token, password }) {
  yield put(LoaderActions.loading());
  const [error, response] = yield call(
    AuthenticationService.changePassword,
    token,
    password
  );
  yield put(LoaderActions.loaded());
  if (error) {
    const { code } = error.response.data;
    if (code === 'ExpiredToken') {
      yield put(
        SnackActions.displayError('reset_password_link_expired', 300000)
      );
      yield call(history.replace, Routes.RESET_PASSWORD);
    } else {
      yield put(SnackActions.displayError('login_failed'));
      return;
    }
  }

  yield put(
    AuthActions.loginSucceed(
      response.data.id,
      response.data.accessToken,
      response.data.refreshToken
    )
  );
  yield call(history.replace, Routes.REDIRECT);
}

function* initPassword({ token, password }) {
  yield put(LoaderActions.loading());
  const [error, response] = yield call(
    AuthenticationService.initPassword,
    token,
    password
  );
  yield put(LoaderActions.loaded());

  if (error) {
    yield put(SnackActions.displayError('reset_password_link_expired'));
    return;
  }

  yield put(
    AuthActions.loginSucceed(
      response.data.id,
      response.data.accessToken,
      response.data.refreshToken
    )
  );

  yield call(history.replace, Routes.REDIRECT);
}

function* register({ form }) {
  yield put(LoaderActions.loading());
  const [error] = yield call(AuthenticationService.register, {
    form,
  });

  if (error) {
    if (error.response.status === CONFLICT) {
      // yield call(history.replace, Routes.DEFAULT_ROUTE);
      yield put(SnackActions.displayError('email_already_exists', 10000));
    } else {
      yield put(SnackActions.displayError('register_failed'));
    }
  } else {
    yield put(SnackActions.displaySuccess('register_succeeded'));
  }

  yield put(LoaderActions.loaded());
}

export default [
  takeLatest(types.LOGIN_REQUESTED, login),
  takeLatest(types.GENERATE_TOKENS_REQUEST, generateTokens),
  takeLatest(types.LOGOUT_REQUESTED, logout),
  takeLatest(types.CHANGE_PASSWORD_REQUESTED, changePassword),
  takeLatest(types.INIT_PASSWORD_REQUESTED, initPassword),
  takeLatest(types.RESET_PASSWORD_REQUESTED, resetPassword),
  takeLatest(types.REGISTER_REQUESTED, register),
];
